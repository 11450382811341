import React from "react"
import { useMediaQuery } from "react-responsive"
import styles from "../../styles/quilmesTourStyles/reservaStep2.module.css"
import "antd/dist/antd.css"
import "../../styles/quilmesTourStyles/index.css"

const ReservaSteps = ({ images }) => {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })

  return (
      <div className={styles.reservaContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>RESERVAR Y COMPRAR</h1>
        </div>
        <div className={styles.stepsContainer}>
          <div>
            <div className={styles.step}>
              <h1 className={styles.stepNumb}>1</h1>
            </div>
            <h1 className={styles.stepText}>FECHA</h1>
          </div>
          <div className={styles.blueBar1}></div>
          <div>
            <div className={styles.step}>
              <h1 className={styles.stepNumb}>2</h1>
            </div>
            <h1 className={styles.stepText}>RESUMEN</h1>
          </div>
          <div className={styles.blueBar}></div>
          <div className={styles.greyBar1}></div>
          <div>
            <div className={styles.step2}>
              <h1 className={styles.stepNumb}>3</h1>
            </div>
            <h1 className={styles.stepText2}>PAGO</h1>
          </div>
        </div>
      </div>
  )
}

export default ReservaSteps
